import React from 'react';
import PropTypes from 'prop-types';
import { GridList, GridListTile, makeStyles } from '@material-ui/core';
import styles from './Product.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  photo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  price: PropTypes.string,
};

const defaultProps = {
  photo: undefined,
  price: undefined,
  description: undefined,
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}));

export default function Product(props) {
  const {
    title, photo, description, price,
  } = props;

  const classes = useStyles();

  return (
    <div className={['col-xs-12', 'col-sm-12', 'col-md-4'].join(' ')}>
      <div className={styles.priceTable}>
        <div className={styles.pHead}>{title}</div>
        <div>
          {(photo || description) && (
          <ul>
            {photo && (
            <li className={styles.productBlockItem}>
              {typeof photo === 'object' ? (
                <div className={classes.root}>
                  <GridList className={classes.gridList} cols={photo.length > 2 ? 1.7 : photo.length}>
                    {photo.map(tile => (
                      <GridListTile key={tile.source}>
                        <img src={tile.source} alt={tile.title} />
                      </GridListTile>
                    ))}
                  </GridList>
                </div>
              ) : (
                <img
                  className="img-responsive img-thumbnail"
                  alt={title}
                  title={title}
                  width="85%"
                  src={photo}
                />
              )}
            </li>
            )}
            {description && (
            <li className={styles.productBlockItem}>{description}</li>
            )}
          </ul>
          )}
          {price && (
          <div>
            <span className={styles.subPriceDetail}>₽</span>
            <span className={styles.priceDetail}>{price}</span>
            <span className={styles.subPriceDetail}>КГ</span>
          </div>
          )}
          <div>
              Свяжитесь с нами для заказа
          </div>
        </div>
      </div>
    </div>
  );
}

Product.propTypes = propTypes;
Product.defaultProps = defaultProps;
