import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-lazy-load-image-component/src/effects/blur.css';

const propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  alt: PropTypes.string,
};

const defaultProps = {
  imageClass: 'img-responsive',
  alt: undefined,
};

// eslint-disable-next-line react/prefer-stateless-function
export default class Image extends Component {
  render() {
    const {
      src, title, imageClass, alt,
    } = this.props;

    return (
      <img
        src={src}
        title={title}
        alt={alt || title}
        className={imageClass}
      />
    );
  }
}

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;
