import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { getSiteTitle, getSiteName, getFrontEndUrl } from './index';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  insertRedirect: PropTypes.bool,
  redirectTime: PropTypes.number,
  URLToRedirect: PropTypes.string,
  canLink: PropTypes.string,
};

/* eslint-disable max-len */
const defaultProps = {
  keywords: 'купить куриное мясо в армавире,купить куриное яйцо в армавире,купить домашнее куриное мясо в армавире,купить домашнее куриное яйцо в армавире,яйцо,армавир,мясо,цесариное мясо',
  insertRedirect: undefined,
  redirectTime: 5,
  URLToRedirect: '/',
  canLink: undefined,
};
/* eslint-enable */

// eslint-disable-next-line react/prefer-stateless-function
export default class HeadPageData extends Component {
  render() {
    const {
      title, description, keywords, insertRedirect, redirectTime, URLToRedirect, canLink,
    } = this.props;
    return (
      <Helmet>
        <title>{getSiteTitle(title)}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={canLink ? (`${getFrontEndUrl}/${canLink}`) : (`${getFrontEndUrl}`)} />

        <meta property="og:locale" content="ru_RU" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${window.location.origin}/favicon.ico`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content={getSiteName} />

        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="Автор" /> */}
        <meta name="twitter:title" content={getSiteTitle(title)} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${window.location.origin}/favicon.ico`} />
        {insertRedirect && (
          <meta httpEquiv="refresh" content={`${redirectTime};${URLToRedirect}`} />
        )}
      </Helmet>
    );
  }
}

HeadPageData.propTypes = propTypes;
HeadPageData.defaultProps = defaultProps;
