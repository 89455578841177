import React from 'react';
import {
  GridList, GridListTile, makeStyles,
} from '@material-ui/core';

import { BlogComponent } from '../../components/Blog';
import { Image } from '../../components/Image';
import styles from './styles.module.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}));

export default function BreeadingCesark() {
  /* eslint-disable max-len */
  const postData = {
    title: 'Разведение цесарок',
    description: 'В этой статье мы хотим поделиться с вами о том, как мы пришли к разведению цесарок и чего нам это стоило',
    keywords: 'цесарки,африканская курица,диетическое мясо',
  };
  /* eslint-enable */

  const classes = useStyles();

  /* eslint-disable global-require */
  const tileData = [
    {
      img: require('./assets/1.jpeg'),
    },
    {
      img: require('./assets/2.jpeg'),
    },
    {
      img: require('./assets/3.jpg'),
    },
    {
      img: require('./assets/4.jpg'),
    },
    {
      img: require('./assets/5.jpg'),
    },
    {
      img: require('./assets/6.jpg'),
    },
    {
      img: require('./assets/7.jpg'),
    },
  ];
  /* eslint-enable */

  /* eslint-disable global-require */
  const eggsData = [
    {
      img: require('./assets/eggs-1.jpeg'),
      title: 'Корзина цесариных яиц',
    },
    {
      img: require('./assets/eggs-2.jpeg'),
      title: 'Цесариные яйца в сене',
    },
  ];
  /* eslint-enable */

  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <BlogComponent
      title={postData.title}
      description={postData.description}
      keywords={postData.keywords}
      canLink="razvedenie-tsesarok"
    >
      <p className={styles.newParagraph}>
          Цесарка - один из видов домашней птицы. Цесарки это родственницы кур, изначально обитавшие в Африке.
          Разновидность данной птицы довольно большая, существуют такие породы как: домашняя, грифовая, серо-крапчатая,
          волжская, голубая, дикая, загорская-белогрудая, кремовая.
      </p>
      <p className={styles.newParagraph}>
          В данной статье пойдет речь о нашем опыте разведения цесарок. Мы занимаемся следующими породами: кремовые,
          загорской-белогрудой, серо-крапчатой. Изначально мы занимались обычными курами-несушками, с целью получения
          яйца. В процессе развития хозяйства было принято решение разводить кур не только ради яйца, но также мяса и
          цыплят.
      </p>
      <div className={classes.root}>
        <GridList className={classes.gridList} cols={1.7}>
          {tileData.map((tile) => (
            <GridListTile key={tile.img}>
              <img src={tile.img} alt={tile.title} />
            </GridListTile>
          ))}
        </GridList>
      </div>
      <h2>Что хорошего в цесарках?</h2>
      <hr />
      <p className={styles.newParagraph}>
        <span className={styles.bigWordInFirstPosition}>Мясо. </span>
        На вкус мясо этой замечательной африканской птицы напоминает фазана. В отличии от куриного мяса, мясо цесарки
        содержит меньше жира. Также мясо содержит много витаминов, микроэлементов, белка и аминокислот в отличии от
        других домашних птиц или дичи. Цесариное мясо часто включают в рацион больных детей, беременных женщин и пожилых
        людей. Мясо низкокаллорийное, диетическое - отлично подойдет для людей, желающих похудеть или вообще следящих за
        тем, что они едят. Чем еще полезно мясо цесарки? В нем содержится очень важный для здоровья витамин В2, важный
        для
        <abbr title="Желудочно-Кишечной Тракт" className="initialism"> ЖКТ</abbr>
        , кожи и зрения, аскорбиновая кислота, сера, кальций, йод, фосфор, кальций и витамин В 12, необходимый
        для лечения анемии.
      </p>
      <p className={styles.newParagraph}>
        <span className={styles.bigWordInFirstPosition}>Яйцо. </span>
        Цесариные яйца имеют грушевидную форму, они короче и шире куриных. Их вес достигает 40–43 г. Скорлупа очень
        прочная, ее окраска может варьироваться от бледно-желтого до густого коричневого оттенка. Часто яйцо цесарки
        может быть двуцветным: окраска градируется от светлого до темного. На ощупь скорлупа яйца цесарки шершавая;
        совершенно гладкие яйца цесарок встречаются довольно редко. Сырое яйцо цесарок, по сравнению с куриным, менее
        водянистое. За сезон (6 месяцев) от каждой цесарки можно получить всего 80–120 яиц. Этим обусловлена редкость и
        дороговизна цесариных яиц в магазинах и ресторанах.
      </p>
      <div className={classes.root}>
        <GridList className={classes.gridList} cols={2}>
          {eggsData.map((tile) => (
            <GridListTile key={tile.img}>
              <img
                src={tile.img}
                alt={tile.title}
                title={tile.title}
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
      <p className={styles.newParagraph}>
        Главной особенностью и гордостью яиц цесарки является уникальная скорлупа, позволяющая хранить их при
        температуре до +10 ºС в течение полугода. В сыром виде и приготовленными всмятку употреблять эти яйца абсолютно
        безопасно, опять же, благодаря толстой скорлупе.
      </p>
      <p className={styles.newParagraph}>
        Традиционная яичница из яиц цесарки имеет легкий нежный вкус. Из яиц цесарки, перемешанных с сахаром и
        охлажденных, получается лакомство, похожее на мороженое.
      </p>
      <p className={styles.newParagraph}>
        Цесариные яйца отличаются высоким содержанием витаминов группы А и каротиноидов. Они не содержат аллергенов и
        поэтому рекомендованы не только всем взрослым, но и детям. В яйцах цесарок содержится безобидно маленькая доля
        холестерина. Более того, яйца цесарок способны излечивать от малокровия, авитаминоза и других болезней.
      </p>
      <p className={styles.newParagraph}>
        Основные ценные компоненты в содержимом цесариных яиц сосредоточены в максимальных количествах, т.к. белок и
        желток содержат очень мало воды. На свойства яиц цесарки влияет высокое на 100 г продукта содержание белков
        (12,7 г), золы (1,0 г), и низкое количество жиров (0,6 г) и углеводов (0,6 г). Благодаря высоким питательным
        качествам яйца цесарки полезны при истощении физических сил, анемии, беременности и в период вскармливания.
        Рекомендуется включать цесариные яйца и в питание спортсменов.
      </p>
      <p className={styles.newParagraph}>
        На картинке ниже вы можете увидеть сравнения цесариного и куриного яйца:
        <Image
          src={require('./assets/eggs-compare.jpeg')} // eslint-disable-line global-require
          title="Сравнение цесариного и куриного яйца"
        />
      </p>
      <hr />
      <p className={[styles.newParagraph, styles.callWithUs].join(' ')}>
        Появилось желания иметь в своем хозяйстве столь полезный вид птицы? Свяжитесь с нами, у нас есть цыплята и
        многое другое!
      </p>
    </BlogComponent>
  );
}
