import React, { Component } from 'react';
import style from './Footer.module.scss';
import { contactEmail, contactPhone } from '../../utils/env';

export default class Footer extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <div className={style.footer}>
        <div className="container">
          <div className="row centered">
            <div className={style.contactInfoHeader}>Есть вопросы? Свяжитесь с нами</div>
            <div className={style.contactInfo}>
              <a className={style.contactInfoLink} href={`mailto:${contactEmail}`} title="Напишите нам">
                {contactEmail}
              </a>
            </div>
            <div className={style.contactInfo}>
              <a
                className={style.contactInfoLink}
                href={`tel:${contactPhone.replace(/[^0-9]/gim, '')}`}
                title="Позвоните нам"
              >
                {contactPhone.replace(/"/gim, '')}
              </a>
            </div>
          </div>
          <div className={style.socialBlock}>
            <a
              href="https://t.me/armavir_farm"
              target="_blank"
              rel="noopener noreferrer"
              className={style.socialLink}
              title="Наш Telegram канал"
            >
              <i className={[style.socialIcon, style.telegram].join(' ')} />
            </a>
            <a
              href="https://www.instagram.com/armavir_farm/"
              target="_blank"
              rel="noopener noreferrer"
              className={style.socialLink}
              title="Наш Instagram"
            >
              <i className={[style.socialIcon, style.instagram].join(' ')} />
            </a>
            <a
              href="https://vk.com/armavir_farm"
              target="_blank"
              rel="noopener noreferrer"
              className={style.socialLink}
              title="Мы ВКонтакте"
            >
              <i className={[style.socialIcon, style.vk].join(' ')} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
