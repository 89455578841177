import React from 'react';
import { NavLink } from 'react-router-dom';

import './NavMenu.scss';

export function NavMenu() {
  return (
    <nav className="navbar navbar-default">
      <div className="container-fluid">
        <div className="navbar-header">
          <div className="navbar-brand">
            <NavLink to="/">Armavir-farm</NavLink>
          </div>
        </div>
        <ul className="nav navbar-nav">
          <li>
            <NavLink exact to="/" activeClassName="active">Наша продукция</NavLink>
          </li>
          <li>
            <NavLink to="/razvedenie-tsesarok" activeClassName="active">Разведение цесарок</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavMenu;
