import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import OurProduct from '../../components/OurProducts';
import Product from '../../components/product';
import { HeadHelmet } from '../../utils';

import styles from './OurProductPage.module.scss';

/* eslint-disable global-require */
const tsesarksImages = [
  {
    source: require('./assets/1.jpg'),
    title: 'Цыплята цесарки',
  },
  {
    source: require('./assets/2.jpg'),
    title: 'Цыплята цесарки',
  },
  {
    source: require('./assets/3.jpg'),
    title: 'Цыплята цесарки',
  },
  {
    source: require('./assets/5.jpg'),
    title: 'Цыплята цесарки',
  },
  {
    source: require('./assets/6.jpg'),
    title: 'Цыплята цесарки',
  },
];
/* eslint-enable */

/* eslint-disable global-require */
const tsesarksEggsImages = [
  {
    source: require('./assets/eggs-1.jpeg'),
    title: 'Яйцо Цесарки',
  },
  {
    source: require('./assets/eggs-2.jpeg'),
    title: 'Яйцо Цесарки',
  },
];
/* eslint-enable */

/* eslint-disable global-require */
const youngChikenPushkinsie = [
  {
    source: require('./assets/pushkinskie/pushkinskie.jpeg'),
    title: 'Пушкинские цыплята',
  },
  {
    source: require('./assets/pushkinskie/4.jpeg'),
    title: 'Пушкинские - Родительское стадо',
  },
];
/* eslint-enable */

/* eslint-disable global-require */
const youngChikenKuchinkie = [
  {
    source: require('./assets/kuchinskie/kuchinskie.jpeg'),
    title: 'Кучинские цыплята',
  },
  {
    source: require('./assets/kuchinskie/1.jpeg'),
    title: 'Кучинская-юбилейная - родительское стадо',
  },
];
/* eslint-enable */

/* eslint-disable global-require */
const youngChikenRodAylend = [
  {
    source: require('./assets/rod-aylend.jpeg'),
    title: 'Род-Айленд цыплята',
  },
];
/* eslint-enable */

/* eslint-disable global-require */
const youngChikenYurlovskie = [
  {
    source: require('./assets/yurlovskie/1.jpeg'),
    title: 'Юрловские цыплята',
  },
  {
    source: require('./assets/yurlovskie/2.jpeg'),
    title: 'Юрловские цыплята',
  },
  {
    source: require('./assets/yurlovskie/3.jpeg'),
    title: 'Юрловские цыплята',
  },
  {
    source: require('./assets/yurlovskie/4.jpeg'),
    title: 'Юрловские - родительское стадо',
  },
  {
    source: require('./assets/yurlovskie/5.jpeg'),
    title: 'Юрловские - яйцо',
  },
];
/* eslint-enable */

/* eslint-disable global-require */
const youngChikenMiniMysnie = [
  {
    source: require('./assets/mini-mysnie/1.jpeg'),
    title: 'Мини-мясные цыплята - 2 недели',
  },
  {
    source: require('./assets/mini-mysnie/2.jpeg'),
    title: 'Мини-мясные - родительское стадо',
  },
];
/* eslint-enable */

export default class HomePage extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <>
        <HeadHelmet
          title="Главная"
          description="Продажа куриного, цесариного яйца и мяса в городе Армавир. Цыплята разных пород и возраста"
        />
        <OurProduct>
          <Product
            title="Цыплята - Юрловские"
            photo={youngChikenYurlovskie}
            description="От одной курицы получают 190-200 яиц в год.
            Скорлупа розово – бурой окраски, вес составляет максимально до 90 грамм. Куры весят – 3-4 кг.
            Петухи значительно крупнее, их вес – 4-5.5 кг."
          />
          <Product
            title="Цыплята - Пушкинские"
            photo={youngChikenPushkinsie}
            description="Мясо-яичная порода.
            Курицы вырастают небольшие — 1.8 – 2 КГ, петухи 2.5-3 КГ.
            Пушкинская порода отличается высокими вкусовыми качествами"
          />
          <Product
            title="Цыплята - мини-мясные"
            photo={youngChikenMiniMysnie}
            description="Минимясные курочки вырастают не более 2.7 кг, при этом петушки их не сильно обгоняют
            и набирают до 3 кг. От одной курицы получают до 180 яиц в год.
            Несмотря на размер самих курочек, яйца получаются до 60 г."
          />
          <Product
            title="Цыплята - Кучинские"
            photo={youngChikenKuchinkie}
            description="Мясо-яичная порода. Курицы вырастают до 3 КГ, петухи до 4 КГ.
            Мясо сочное, высочайшего качества, обладает великолепным ароматом.
            Содержание белка в мясе превышает показатели бройлерных кур"
          />
          <Product
            title="Цыплята - Род-Айленд"
            photo={youngChikenRodAylend}
            description="Мясо-яичная порода. Курицы вырастают до 3.2 КГ, петухи до 4 - 4.2 КГ.
            Средняя яйценосткость в год - 160 - 170 яиц"
          />
          <Product
            title="Цыплята цесарки"
            description={(
              <>
                Прочтите нашу
                {' '}
                <NavLink to="/razvedenie-tsesarok" className={styles.linkToArticle}>статью</NavLink>
                , чтобы узнать почему разведение цесарок очень выгодно
              </>
            )}
            photo={tsesarksImages}
          />
          <Product title="Цесариное яйцо" photo={tsesarksEggsImages} />
          <Product title="Куриное мясо" />
          <Product title="Куриное яйцо" />
        </OurProduct>
      </>
    );
  }
}
