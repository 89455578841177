import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import 'react-lazy-load-image-component/src/effects/blur.css';

const propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

// eslint-disable-next-line react/prefer-stateless-function
export default class MyImage extends Component {
  render() {
    const {
      src, title,
    } = this.props;

    return (
      <LazyLoadImage
        src={src}
        title={title}
        alt={title}
        effect="blur"
        className="img-responsive"
      />
    );
  }
}

MyImage.propTypes = propTypes;
