import {
  siteTitle,
  frontEndUrl,
} from './env';

// eslint-disable-next-line import/no-cycle
export {
  default as HeadHelmet,
} from './HeadPageData';

/**
 * Return site title
 * @param {?string} title
 * @returns {string}
 */
export const getSiteTitle = (title) => { // eslint-disable-line import/prefer-default-export
  return `${siteTitle}${title ? ` | ${title}` : ''}`;
};

/**
 * Const with site name
 * @type {string}
 */
export const getSiteName = siteTitle;

/**
 * Const with front end url
 * @type {string}
 */
export const getFrontEndUrl = frontEndUrl;
