import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HeadHelmet } from '../../utils';
import styles from './Blog.module.css';

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  canLink: PropTypes.string.isRequired,
};

const defaultProps = {
  keywords: undefined,
};

// eslint-disable-next-line react/prefer-stateless-function
export default class Blog extends Component {
  componentDidMount() {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.innerHTML = 'VK.Widgets.Comments("vk_comments", {limit: 5, attach: "photo"});';

    document.getElementById('script_vk_comments').appendChild(script);
  }

  render() {
    const {
      title, children, description, keywords, canLink,
    } = this.props;
    return (
      <React.Fragment>
        <HeadHelmet
          title={title}
          description={description}
          keywords={keywords}
          canLink={canLink}
        />
        <div className="container">
          <div className="row">
            <div className={['col-xs-12', 'col-sm-10', 'col-md-9', styles.blogMain].join(' ')}>
              <div className={styles.blogPost}>
                <h1 className={styles.blogPostTitle}>{title}</h1>
                <p>{description}</p>
                <hr />
                {children}
                <hr />
                <div id="vk_comments" />
                <div id="script_vk_comments" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Blog.propTypes = propTypes;
Blog.defaultProps = defaultProps;
