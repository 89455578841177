import React, { Component } from 'react';
import { HeadHelmet } from '../../utils';
import styles from './PageNotFound.module.css';

export default class HomePage extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <React.Fragment>
        <HeadHelmet
          title="404: Страница не найдена"
          description="404: Страница не найдена"
          insertRedirect={1}
          redirectTime={5}
          URLToRedirect="/"
        />
        <div className="container">
          <div className="row">
            <div className={['col-xs-12', 'col-sm-10', 'col-md-9', styles.main].join(' ')}>
              <div className={styles.content}>
                <h1 className={styles.title}>404: Страница не найдена</h1>
                <hr />
                Запрашиваемая страница не найдена!
                <hr />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
