import React, { Component } from 'react';
import styles from './Header.module.css';

class Header extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 centered">
              <div className={styles.headerText}>Продажа куриного мяса и яиц в Армавире</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
