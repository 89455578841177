import React from 'react';

import { Footer as FooterComponent } from '../../components/footer';

function AppFooterLayout() {
  return (
    <React.Fragment>
      <FooterComponent />
    </React.Fragment>
  );
}

export default AppFooterLayout;
