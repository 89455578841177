import React from 'react';
import { Header as HeaderComponent } from '../../components/header';
import { NavMenu } from '../../components/NavMenu';

function AppHeaderLayout() {
  return (
    <React.Fragment>
      <HeaderComponent />
      <NavMenu />
    </React.Fragment>
  );
}

export default AppHeaderLayout;
