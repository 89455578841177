import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
};

export default class OurProduct extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const {
      children,
    } = this.props;
    return (
      <div className="container ptb">
        <div className="row centered">
          <h1 className="mb">Наша продукция</h1>
          {children}
        </div>
      </div>
    );
  }
}

OurProduct.propTypes = propTypes;
